import { loadStripe } from '@stripe/stripe-js/pure';
import currency from 'app/views/helpers/currency';
import telemetry from 'app/views/helpers/telemetry';

let stripeInstancePromise = null;
let stripePaymentRequest = null;

let stripeElement = null;
let stripeElementMode = null;
let stripeElementAmount = null;
let stripeExpressCheckout = null;

let stripeExpressCheckoutClickEventOptions = null;

const initializeStripe = async (stripeApiKey) => {
  stripePaymentRequest = null;
  stripeElement = null;
  stripeExpressCheckout = null;
  stripeInstancePromise = loadStripe(stripeApiKey);
};

// Check if the browser supports Payment Request / Apple Pay
const stripeApplePayEnabledOnBrowser = () => {
  return !!window.PaymentRequest && !!window.ApplePaySession
    && window.ApplePaySession.canMakePayments();
};

const createStripePaymentRequestOptions = (stripeAccountId, totalAmount, displayName, requireEmail, requirePhone, displayItems) => {
  return {
    country: 'US',
    currency: currency.getCurrency().code.toLowerCase(),
    total: {
      label: displayName,
      amount: totalAmount,
    },
    requestPayerName: true,
    requestPayerEmail: !!requireEmail,
    requestPayerPhone: !!requirePhone,
    onBehalfOf: stripeAccountId,
    displayItems: displayItems,
    disableWallets: ['browserCard', 'link', 'googlePay'] //disable wallets we do not support via Stripe Payment Request
  };
};

const createStripePaymentRequest = async (formSessionId, stripeAccountId, totalAmount, displayName, requireEmail, requirePhone, displayItems) => {
  try {
    // Ensure the Stripe instance is loaded
    let stripeInstance = await stripeInstancePromise;

    // Set up Stripe Payment Request options from the form data
    let stripePaymentRequestOptions = createStripePaymentRequestOptions(stripeAccountId, totalAmount, displayName, requireEmail, requirePhone, displayItems);

    // Create Stripe Payment Request
    stripePaymentRequest = stripeInstance.paymentRequest(stripePaymentRequestOptions);

    telemetry.trackEvent({
      name: 'Stripe: Payment Request Created',
      properties: {
        StripeAccountId: stripeAccountId,
        Amount: totalAmount,
        FormSessionId: formSessionId
      }
    });

    // Check if the payment request can be made and return payment method availability
    // this is necessary before showing the payment request interface .show()
    let result = await stripePaymentRequest.canMakePayment();

    return {
      applePayEnabled: !!result?.applePay,
      googlePayEnabled: !!result?.googlePay,
      linkEnabled: !!result?.link,
      browserEnabled: !!result?.browser,
    };
  }
  catch (e) {
    telemetry.trackException({
      exception: e || new Error('Stripe: Create Payment Request Error'),
      properties: {
        ErrorType: 'Stripe: Create Payment Request Exception',
        StripeAccountId: stripeAccountId,
        Amount: totalAmount,
        FormSessionId: formSessionId
      }
    });
  }
};

const buildPaymentResponseFillDictionary = (paymentResponse) => {
  // Create Fill Dictionary
  var billingContact = paymentResponse?.token?.card;
  // Stripe only provides the full name in the billing contact
  // Split into first and last name
  var nameParts = billingContact?.name?.split(' ');
  var firstName = nameParts[0];
  var lastName = nameParts[nameParts.length-1];

  var dict = {
    FirstName: firstName,
    LastName: lastName,
    AddressLine1: billingContact?.address_line1,
    AddressLine2: billingContact?.address_line2,
    City: billingContact?.address_city,
    StateProvince: billingContact?.address_state,
    PostalCode: billingContact?.address_zip,
    Country: billingContact?.address_country,
    EmailAddress: paymentResponse?.payerEmail,
    HomePhone: paymentResponse?.payerPhone?.replace(/\D/g, ''),
    MobilePhone: paymentResponse?.payerPhone?.replace(/\D/g, '')
  };

  return dict;
};

const promptStripePaymentRequest = async (formSessionId, stripeAccountId, totalAmount, displayName, displayItems) => {
  try {
    // Stripe Payment Request requires that the code that shows the (Apple Pay) payment sheet must be invoked directly by a user activation event, like a click or a touch gesture.
    // We do that here by placing the .show() before any async or long-running code (at or near the top of the event handler).

    stripePaymentRequest.update({
      currency: currency.getCurrency().code.toLowerCase(),
      total: {
        label: displayName,
        amount: totalAmount,
      },
      displayItems: displayItems,
    });

    stripePaymentRequest.off('token');
    stripePaymentRequest.off('cancel');

    // Show the Stripe Payment Request Interface
    stripePaymentRequest.show();

    let handleEventsPromise = new Promise((resolve) => {
      // Handle the token event
      stripePaymentRequest.on('token', function (paymentResponse) {
        var dict = buildPaymentResponseFillDictionary(paymentResponse);

        // Complete event
        paymentResponse.complete('success');

        telemetry.trackEvent({
          name: 'Stripe: Payment Request Token Created',
          properties: {
            StripeAccountId: stripeAccountId,
            Amount: totalAmount,
            FormSessionId: formSessionId
          }
        });

        resolve({
          paymentResponseDetails: paymentResponse,
          fill_dict: dict
        });
      });

      // Handle the cancel event
      stripePaymentRequest.on('cancel', function () {
        telemetry.trackEvent({
          name: 'Stripe: Payment Request canceled by user',
          properties: {
            StripeAccountId: stripeAccountId,
            Amount: totalAmount,
            FormSessionId: formSessionId
          }
        });

        resolve({
          stripePaymentInternalError: false
        });
      });
    });

    // Wait for the token or cancel event to resolve to get response data
    return await handleEventsPromise;
  }
  catch (e) {
    telemetry.trackException({
      exception: e || new Error('Stripe: Prompt Payment Request Error'),
      properties: {
        ErrorType: 'Stripe: Prompt Payment Request Exception',
        StripeAccountId: stripeAccountId,
        Amount: totalAmount,
        FormSessionId: formSessionId
      }
    });

    return {
      stripePaymentInternalError: true
    };
  }
};

const createStripeElementOptions = (stripeAccountId, totalAmount) => {
  return {
    mode: stripeElementMode,
    ...stripeElementMode === 'payment' && totalAmount > 0 && {amount: Math.round(totalAmount * 100)},
    currency: currency.getCurrency().code.toLowerCase(),
    setupFutureUsage: 'off_session',
    onBehalfOf: stripeAccountId
  };
};

const setStripeElementMode = (frequency) => {
  if (frequency === 5) {
    // For Quarterly recurring contributions, create the Stripe Element with 'setup' mode
    stripeElementMode = 'setup';
  }
  else {
    // For one-time contributions, create the Stripe Element with 'payment' mode
    stripeElementMode = 'payment';
  }
};

const createStripeElement = async (stripeAccountId, totalAmount, frequency) => {
  // Ensure the Stripe instance is loaded
  let stripeInstance = await stripeInstancePromise;

  // Set stripe element mode based on frequency
  setStripeElementMode(frequency);

  let stripeElementOptions = createStripeElementOptions(stripeAccountId, totalAmount);

  if (!stripeElement) {
    stripeExpressCheckout = null;
    stripeElement = stripeInstance.elements(stripeElementOptions);
  } else {
    stripeElement.update(stripeElementOptions);
  }
};

const createStripeExpressCheckoutOptions = (paymentMethods) => {
  return {
    buttonType: {
      applePay: 'plain',
      googlePay: 'plain'
    },
    paymentMethods: {
      applePay: paymentMethods.includes('applePay') ? 'auto' : 'never',
      googlePay: paymentMethods.includes('googlePay') ? 'always' : 'never',
      amazonPay:  paymentMethods.includes('amazonPay') ? 'auto' : 'never',
      paypal: 'never',
      link: 'never'
    },
    paymentMethodOrder: paymentMethods,
    layout: { overflow: 'auto', maxColumns: 2, maxRows: 1 },
  };
};

const buildPaymentDetailsFillDictionary = (paymentResult) => {
  // Create Fill Dictionary
  var billingDetails = paymentResult?.confirmationToken?.payment_method_preview?.billing_details;
  // Stripe only provides the full name in the billing contact
  // Split into first and last name
  var nameParts = billingDetails?.name?.split(' ');
  var firstName = nameParts[0];
  var lastName = nameParts[nameParts.length-1];

  var details = {
    FirstName: firstName,
    LastName: lastName,
    AddressLine1: billingDetails?.address?.line1,
    AddressLine2: billingDetails?.address?.line2,
    City: billingDetails?.address?.city,
    StateProvince: billingDetails?.address?.state,
    PostalCode: billingDetails?.address?.postal_code,
    Country: billingDetails?.address?.country,
    EmailAddress: billingDetails?.email,
    HomePhone: billingDetails?.phone?.replace(/\D/g, ''),
    MobilePhone: billingDetails?.phone?.replace(/\D/g, '')
  };

  return details;
};

const createStripeExpressCheckoutClickEventOptions = (requireEmail, requirePhone, displayItems, displayName) => {
  // convert displayItem amounts to cents
  displayItems = displayItems.map(item => {
    return {
      amount: Math.round(item.amount * 100),
      name: item.name
    };
  });

  return {
    emailRequired: !!requireEmail,
    phoneNumberRequired: !!requirePhone,
    ...stripeElementMode === 'payment' && {lineItems: displayItems},
    business: {
      name: displayName
    }
  };
};

const createStripeExpressCheckout = async (formSessionId, stripeAccountId, paymentMethods, totalAmount, displayName, requireEmail, requirePhone, displayItems, frequency) => {
  stripeElementAmount = totalAmount;

  // Get the Stripe Element
  await createStripeElement(stripeAccountId, stripeElementAmount, frequency);

  // Do not create a new Stripe Express Checkout element if it already exists, just mount it
  if (stripeExpressCheckout) {
    // Mount the Express Checkout element
    stripeExpressCheckout.mount('.at-express-checkout-element-wrapper');
    return;
  }

  let stripeExpressCheckoutOptions = createStripeExpressCheckoutOptions(paymentMethods);
  stripeExpressCheckout = stripeElement.create('expressCheckout', stripeExpressCheckoutOptions);

  telemetry.trackEvent({
    name: 'Stripe: Express Checkout Element Created',
    properties: {
      StripeAccountId: stripeAccountId,
      Amount: stripeElementAmount,
      FormSessionId: formSessionId
    }
  });

  // Mount the Express Checkout element
  stripeExpressCheckout.mount('.at-express-checkout-element-wrapper');

  // Handle Express Checkout cancel event
  stripeExpressCheckout.on('cancel', function() {
    telemetry.trackEvent({
      name: 'Stripe: Express Checkout canceled by user',
      properties: {
        StripeAccountId: stripeAccountId,
        Amount: stripeElementAmount,
        FormSessionId: formSessionId
      }
    });
  });

  // Set up the click event options in module-scope variable to access when amount changes
  stripeExpressCheckoutClickEventOptions = createStripeExpressCheckoutClickEventOptions(requireEmail, requirePhone, displayItems, displayName);
  // Handle Express Checkout click event
  stripeExpressCheckout.on('click', (event) => {
    event.resolve(stripeExpressCheckoutClickEventOptions);
  });

  return stripeExpressCheckout;
};

const createStripeConfirmationToken = async (formSessionId, stripeAccountId) => {
  let stripeInstance = await stripeInstancePromise;

  return stripeInstance.createConfirmationToken({
    elements: stripeElement,
  })
  .then(function(result) {
    if (result.confirmationToken) {
      telemetry.trackEvent({
        name: 'Stripe: Express Checkout confirmed',
        properties: {
          StripeAccountId: stripeAccountId,
          Amount: stripeElementAmount,
          ConfirmationTokenId: result.confirmationToken?.id,
          FormSessionId: formSessionId
        }
      });

      // Get contact and billing details from result
      const parsedInfo = buildPaymentDetailsFillDictionary(result);

      return {
        confirmationToken: result.confirmationToken,
        fill_dict: parsedInfo,
        amount: stripeElementAmount
      };
    }
    else {
      return{
        stripePaymentInternalError: true
      };
    }
  });
};

const updateStripeElementAmount = (newTotalAmount) => {
  if (stripeElement && stripeExpressCheckout && newTotalAmount > 0) {
    // Update the total amount in the global variable regardless of mode
    stripeElementAmount = newTotalAmount;

    if (stripeElementMode === 'payment') {
      stripeElement.update({
        amount: Math.round(newTotalAmount * 100)
      });
    }

    // Update the lineItems amount in the click event options if any
    if (!!stripeExpressCheckoutClickEventOptions?.lineItems) {
      stripeExpressCheckoutClickEventOptions.lineItems.forEach(element => {
        element.amount = Math.round(newTotalAmount * 100);
      });
    }
  }
};

const updateStripeElementRecurrence = (frequency, displayName) => {
  if (stripeElement && stripeExpressCheckout) {
    setStripeElementMode(frequency);
    stripeElement.update({
      mode: stripeElementMode,
      ...stripeElementMode === 'payment' && {amount: Math.round(stripeElementAmount * 100)}
    });

    // Reset the options lineitem on each update
    if (!!stripeExpressCheckoutClickEventOptions?.lineItems) {
      delete stripeExpressCheckoutClickEventOptions.lineItems;
    }

    // lineItems is only required for payment mode && not for one-time contributions
    if (stripeElementMode === 'payment') {
      var displayItems = [];
      if (frequency !== 0) {
        displayItems.push({
          name: displayName,
          amount: Math.round(stripeElementAmount * 100)
        });
      }

      stripeExpressCheckoutClickEventOptions = {...stripeExpressCheckoutClickEventOptions, lineItems: displayItems};
    }
  }
};

export default {
  initializeStripe,
  stripeApplePayEnabledOnBrowser,
  createStripeElement,
  createStripePaymentRequest,
  promptStripePaymentRequest,
  createStripeExpressCheckout,
  createStripeConfirmationToken,
  updateStripeElementAmount,
  updateStripeElementRecurrence
};
